/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CloseRoundProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const CloseRound: React.FC<CloseRoundProps> = ({size, ...props}) => (
  <svg viewBox="0 0 256 256" fill="currentColor" width={ size || "256" } height={ size || "256" } {...props}>
    <path d="M37.4903 37.4903C61.495 13.4857 94.0523 0 128 0C161.948 0 194.505 13.4857 218.51 37.4903C242.514 61.495 256 94.0523 256 128C256 161.948 242.514 194.505 218.51 218.51C194.505 242.514 161.948 256 128 256C94.0523 256 61.495 242.514 37.4903 218.51C13.4857 194.505 0 161.948 0 128C0 94.0523 13.4857 61.495 37.4903 37.4903ZM128 16.9339C98.5434 16.9339 70.2933 28.6354 49.4644 49.4644C28.6354 70.2933 16.9339 98.5434 16.9339 128C16.9339 157.457 28.6354 185.707 49.4644 206.536C70.2933 227.365 98.5434 239.066 128 239.066C157.457 239.066 185.707 227.365 206.536 206.536C227.365 185.707 239.066 157.457 239.066 128C239.066 98.5434 227.365 70.2933 206.536 49.4644C185.707 28.6354 157.457 16.9339 128 16.9339ZM181.79 74.2004C185.097 77.5067 185.097 82.8677 181.791 86.1744L139.971 127.999L181.803 169.827C185.11 173.133 185.11 178.494 181.804 181.801C178.498 185.108 173.137 185.108 169.83 181.802L127.998 139.974L86.1751 181.801C82.8687 185.108 77.5078 185.108 74.2011 181.802C70.8943 178.495 70.894 173.134 74.2004 169.828L116.023 128L74.1928 86.1751C70.8861 82.8687 70.8858 77.5078 74.1921 74.2011C77.4985 70.8943 82.8594 70.894 86.1662 74.2004L127.996 116.026L169.816 74.2011C173.123 70.8943 178.484 70.894 181.79 74.2004Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
CloseRound.displayName = 'CloseRound';
export default CloseRound;
/* tslint:enable */
/* eslint-enable */
